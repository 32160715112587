import React from "react"
import { StaticQuery, graphql } from "gatsby"
import styles from "./layout.module.scss"

import Header from './header';
import Footer from './footer';
import Banner from './banner';

export default ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title,
            desc,
            siteUrl
          }
        },
        pageContact: allWordpressPage (filter: { slug: { eq: "contact" } } ) {
          edges {
            node {
              slug,
              title,
              content,
              custom_fields {
                hours,
               	phone,
                email,
                address
              }
            }
          }
        },
        banner: allWordpressPage (filter: { slug: { eq: "banner" } } ) {
          edges {
            node {
              slug,
              title,
              content,
             	acf {
                enable_banner
             	  banner_title
             	  banner_content
                banner_options {
                  banner_color
                  dismissible
                  dismiss_button_text
                  place_cookie
                  cookie_name
                }
             	}
            }
          }
        }
      }
    `
  }
    render={data => (
      <div
        className={styles.fumewrap}
        style={{
          margin: '0 auto',
          padding: '0'
        }}
      >
        <Header siteTitle={data.site.siteMetadata.title} />
        <div className={styles.pagecontent}>
          <Banner siteTitle={data.site.siteMetadata.title} bannerContent={data.banner} />
          {children}
        </div>
        <Footer siteTitle={data.site.siteMetadata.title} contactInfo={data.pageContact} />
      </div>
    )}
  />
)
