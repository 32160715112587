import React, { useState, useEffect } from "react";
import Cookie from 'universal-cookie';
import styles from "./banner.module.scss";
import "./banner-bgs.scss";

const cookie = new Cookie();

function Banner ({ bannerContent }) {
  const [bannerCookie, setBannerCookie] = useState(false);
  const banner = bannerContent.edges[0].node.acf;
  const bannerEnabled = banner.enable_banner;
  const cookiesEnabled = banner.banner_options.place_cookie;
  const cookieName = banner.banner_options.cookie_name;

  useEffect(() => {
    if (bannerEnabled === true && cookiesEnabled === true && cookie.get(cookieName)) {
      setBannerCookie(true);
    }
  }, [bannerEnabled, cookiesEnabled, cookieName]);

  function handleClick(e) {
    setBannerCookie(true);
    if (!bannerCookie) {
      cookie.set(cookieName, true, { path: '/' });
    }

  }
  if (bannerCookie === true || bannerEnabled === false){
    return null;
  } else {
    return (
      <div className={`${styles.banner}${bannerCookie === false ? ` js-show-banner` : ``}`}>
        <div className={`banner-${banner.banner_options.banner_color}`} key={banner.banner_title.split(' ').join('')}>
          <h3>{banner.banner_title}</h3>
          <div className={styles.bannerContent} dangerouslySetInnerHTML={{__html: banner.banner_content }} />
          {banner.banner_options.dismissible && (
            <button className={styles.dismissButton} onClick={handleClick} data-cookie-name={banner.banner_options.cookie_name}>{banner.banner_options.dismiss_button_text}</button>
          )}
        </div>
      </div>
    )
  }
}

export default Banner;
