import React, {Component} from "react"
import Link from "gatsby-link"

import styles from "./nav.module.scss";

class MainMenu extends Component {
    render() {

        const data = this.props.menu.allWordpressWpApiMenusMenusItems.edges[0].node.items

        return (
              <div>
              {data.map((item, index) =>
                <div className={styles.item} key={index}>
                  {item.object === 'custom' ?
                      <a href={item.url} target="_blank" rel="noopener noreferrer">{item.title}</a>
                    :
                    <Link
                      to={item.object === 'custom' ?  item.url : item.object_slug === `home` ? `/` : item.object_slug}
                      className={styles.logo}
                    >
                      {item.title}
                    </Link>
                  }
                </div>
              )}
              </div>
      )
    }
}

export default MainMenu
